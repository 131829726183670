import React from "react"
import Layout from '@components/layout/layout.jsx'
import DocumentHeadMeta from '@components/utility/SEO.jsx'
import { Link } from "gatsby"

//Components

// markup
const notFoundPage = ({ data }) => {
  return (
    <Layout addedClass="page--not-found">
      <DocumentHeadMeta title='Page Not Found | ALL IN&reg; for Lupus Nephritis' description='' robots="noindex" canonicalURL='https://www.allinforlupusnephritis.com/404' />
      <section className="bg-white">
        <div className="container container--narrow">
          <h1>Not found</h1>
            <p><strong>The page you requested could not be found.</strong></p>
          <Link to="/" className="cta--body cta--purple">Home</Link>
        </div>
      </section>  
    </Layout>
  )
}

export default notFoundPage
